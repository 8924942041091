<template>
    <figure class="image-item" @mousemove="moveFigure" @click="toMaterialView">
        <FadeUp
          :src="srcImage"
          :src-placeholder="require('@/assets/img/default-img.jpg')"/>
          <figcaption :class="setClassFig" v-if="!data?.image && data?.fecha" :style="familyCursorStyle">
            <div v-if="!data?.foto_file" :class="classColor" :style="{backgroundColor: getBackgroundColor(data)}"></div>
            <div class="image-item-family-info-container">
                <h3 class="bcLiguria">{{ data.nombre_lang[this.$i18n.locale] }}</h3>
                <p class="bcLiguria">{{ data.estudio }}</p>
                <!-- <div v-if="!data?.foto_file">
                    <p>{{ getObraProducts(data) }} - {{ getFamilias(data) }}</p>
                </div> -->
            </div>
          </figcaption>
          <figcaption :class="setClassFig" v-if="!data?.image && data?.foto_file" :style="familyCursorStyle">
            <div>
                <h3>{{ data.nombre_lang[this.$i18n.locale] }}</h3>
            </div>
          </figcaption>
          <figcaption class="image-item-video" v-if="data?.video_link">
            <div class="text-white">
              <h3>{{ data?.title }}</h3>
            </div>
            <PlayIcon/>
          </figcaption>
          <figcaption @click="toProductFamily" class="image-item-material-family-product" v-if="data?.fotos && !data?.fecha">
            <h3>{{ data?.nombre }}</h3>
          </figcaption>
    </figure>
</template>
<script>
import FadeUp from '../lazy-load-images/FadeImage'
import PlayIcon from '@/assets/img/play-icon.svg'
import { EventBus } from '@/event-bus'
export default {
  name: 'Image',
  props: ['data', 'color'],
  components: {
    FadeUp,
    PlayIcon
  },
  data () {
    return {
      classFamilyFig: 'image-item-family',
      classColor: 'image-item-color',
      classMaterialFig: 'image-item-material-figcaption',
      classVideoProductFig: 'image-item-video',
      familyCursorStyle: {
        left: '0',
        top: '0'
      }
    }
  },
  computed: {
    srcImage () {
      return this.data?.foto_file ?? this.data?.fotoFile ?? this.data?.image ?? this.verifyIsArray(this.data?.fotos) ?? this.data?.foto
    },
    setClassFig () {
      return this.data?.foto_file ? this.classMaterialFig : this.classFamilyFig
    },
    setFamilyCursor () {
      return this.data?.fotoFile ?? this.familyCursorStyle
    }
  },
  methods: {
    getFirstProduct (obra) {
      if (obra.productos !== undefined && obra.productos.length > 0) {
        return obra.productos[0]
      }
      return null
    },
    getObraProducts (obra) {
      let productos = ''
      if (obra.productos !== undefined && obra.productos !== null) {
        obra.productos.forEach((p, index) => {
          productos += p.nombre_lang[this.$i18n.locale]
          if (index < (obra.productos.length - 1)) {
            productos += ' - '
          }
        })
      }
      return productos
    },
    getFamilias (obra) {
      let familias = ''
      if (obra.productos !== undefined && obra.productos !== null) {
        obra.productos.forEach((p, index) => {
          // familias += (p.familia.nombre_lang[this.$i18n.locale] !== undefined) ? p.familia.nombre_lang[this.$i18n.locale] : p.nombre_lang[this.$i18n.locale]
          familias += p.nombre_lang[this.$i18n.locale]
          if (index < (obra.productos.length - 1)) {
            familias += ' - '
          }
        })
      }
      return familias
    },
    getProductFamilyColor (producto) {
      if (producto !== null && producto.familia !== undefined) {
        return producto.familia.color
      }
      return '#000000'
    },
    getBackgroundColor (obra) {
      if (this.productosNoDuplicateByFamily(obra).length > 1) {
        return '#000000CC'
      }
      return this.getProductFamilyColor(this.getFirstProduct(obra)) + 'E6'
    },
    productosNoDuplicateByFamily (obra) {
      if (obra.productos !== undefined) {
        const prodNoDuply = []
        obra.productos.forEach((p) => {
          if (!prodNoDuply.some((p2) => p2.familia.id === p.familia.id)) {
            prodNoDuply.push(p)
          }
        })
        return prodNoDuply
      }
      return []
    },
    moveFigure (event) {
      if (this.data?.fotoFile) {
        const parentRect = event.currentTarget.getBoundingClientRect()
        const x = (event.clientX - parentRect.left)
        const y = (event.clientY - parentRect.top)
        this.familyCursorStyle.left = `${x}px`
        this.familyCursorStyle.top = `${y}px`
      }
    },
    updateScrollPosition (event) {
      if (this.data?.fotoFile) {
        const parentRect = event.currentTarget.getBoundingClientRect()
        const x = (event.clientX - parentRect.left) + 6
        this.familyCursorStyle.left = `${x}px`
        this.familyCursorStyle.top = `${window.scrollY}px`
      }
    },
    toProductFamily () {
      this.$router.push({ name: 'product-page', params: { product: this.data.id + '-' + this.data.path }, query: { foto: this.verifyIsArray(this.data?.fotos), name: this.data?.nombre, color: this.color } })
    },
    toMaterialView () {
      // this.$router.push({ name: 'Material', params: {} } )
      if (this.data?.foto_file) {
        const name = this.data?.nombre_lang[this.$i18n.locale].split(' ').join('-')
        this.$router.push({ name: 'Material', params: { material: this.data.id + '-' + name } })
      }
      if (this.data?.fotoFile) {
        EventBus.$emit('showWork', this.data, this.getBackgroundColor(this.data))
      }
    },
    verifyIsArray (data) {
      if (Array.isArray(data) && data.length > 0) {
        return data[0].foto
      }
    }
  },
  mounted () {
    window.addEventListener('scroll', this.updateScrollPosition)
  }
}
</script>

<style lang="scss" scoped>
    .image-item{
        position: relative;
        pointer-events: all;
        &-family{
            // max-width: 30vw;
            background-color: #1a1a1a9d;
            position: absolute;
            z-index: 100;
            white-space: nowrap;
            overflow: hidden;
            padding: 8px 20px 8px 8px;
            // padding-inline: 10px;
            // padding-block: 50px;
            color: white;
            border-radius: var(--main-space);
            display: inline-flex;
            align-items: center;
            // justify-self: center;
            pointer-events: none;
            opacity: 0;
            gap: 8px;
            &-info-container {
              display: flex;
              flex-direction: column;
              height: fit-content;
              h3{
                  font-size: 14px;
                  margin: 0;
                  padding: 0;
                  // line-height: 15;
              }
              p{
                font-size: 14px;
                  margin: 0;
                  padding: 0;
                  // line-height: 15;
              }
            }
        }
        &-video{
          width: 100%;
          height: 100%;
          background-color: #1a1a1a9d;
          color: white;
          position: absolute;
          top: 0;
          display: flex;
          justify-content: center;
          z-index: 99;
          align-items: center;
          transition: all 0.3s ease-in-out;
          text-align: center;
          opacity: 0;
          div{
            width: 100%;
            position: relative;
            z-index: 10000;
          }
          svg{
              position: absolute;
              bottom: 3rem;
              left: 2rem;
              width: var(--main-space);
              height: var(--main-space);
              fill: white
          }
        }
        &-material-figcaption{
            position: absolute;
            height: 3rem;
            left: 3% !important;
            top: 85.5% !important;
            padding-block-start: 0.8rem;
            padding-block-end: 0.5rem;
            padding-inline: 40px;
            background-color: #1A1A1A;
            color: white;
            opacity: 1;
            transition: all 0.3s ease-in-out;
            h3{
              font-size: 1rem;
              font-weight: bold;
              font-family: 'BC-Liguria';
            }
        }
        &-color{
            display: flex;
            // padding: 20px;
            width: 40px;
            height: 40px;
            border-radius: 100%;
        }
    }
    .image-item-material-family-product{
            position: absolute;
            background: #1a1a1a81;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 100;
            transition: all 0.3s ease-in-out;
            // opacity: 0;
            h3{
              color: white;
              font-size: 3rem;
              font-weight: bold;
              font-family: 'BC-Liguria';
            }
        }
        // .image-item-material-family-product:hover{
        //   opacity: 1;
        // }
    .image-item{
        &:hover{
            .image-item-figcaption{
                opacity: 1;
            }
        }
        &:hover{
            .image-item-family{
                opacity: 1;
            }
        }
         &:hover{
            .image-item-video{
                opacity: 1;
            }
        }
    }
    figure{
        width: 100%;
        height: 100%;
        margin: 0;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    @include media-breakpoint-down(lg){
        .family-item-figcaption{
            left: 20%;
            top: 40%;
        }
        .image-item-material-figcaption{
            position: absolute;
            height: 3rem;
            left: 3% !important;
            top: 80% !important;
            padding-block-start: 0.8rem;
            padding-block-end: 0.5rem;
            padding-inline: 40px;
            background-color: #1A1A1A;
            color: white;
            opacity: 1;
            transition: all 0.3s ease-in-out;
            h3{
              font-size: 1rem;
              font-weight: bold;
              font-family: 'BC-Liguria';
            }
        }

    }
    @include media-breakpoint-down(md){
      .image-item{
        &-material-figcaption{
          left: 0!important;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 4px 8px;
          h3{
            margin: 0;
            font-size: 16px;
          }
        }
      }
      .family-item-figcaption{
          padding:0.5rem;
          h3{
              font-size: 0.8rem;
          }
          p{
              font-size: 0.5rem;
          }
      }
    }
</style>
