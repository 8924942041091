<template>
  <div :class="classes">
    <a v-if="variant === 'link'" :href='link' target="_blank" class="icon-container" :class="'icon-container-' + name">
      <component :is="icono"></component>
    </a>
    <router-link v-if="variant === 'router-link'" :to="link" class="icon-container" :class="'icon-container-' + name">
      <component :is="icono"></component>
    </router-link>
    <div v-if="variant === 'language'" class="icon-container" @click="$emit('changeLanguage')">
      <component :is="icono"></component>
    </div>
    <div v-if="variant === undefined" class="icon-container">
      <component :is="icono"></component>
    </div>
  </div>
</template>

<script>
import languageIconEN from '../assets/img/iconoEN.svg'
import languageIconES from '../assets/img/iconoES.svg'
import mailIcon from '../assets/img/mail-icon.svg'
import youtubeIcon from '../assets/img/youtube-icon.svg'
import linkedinIcon from '../assets/img/linkedin-icon.svg'
import instagramIcon from '../assets/img/instagram-icon.svg'
import whatsappIcon from '../assets/img/whatsapp.svg'
import TelefonoLogo from '../assets/img/telefono-icon.svg'

export default {
  name: 'floatingButton',
  props: ['icono', 'variant', 'link', 'name', 'classes'],
  components: {
    languageIconEN,
    languageIconES,
    mailIcon,
    youtubeIcon,
    linkedinIcon,
    instagramIcon,
    whatsappIcon,
    TelefonoLogo
  },
  mounted () {

  }
}
</script>

<style lang="scss">
  .icon-container {
    width: var(--main-space);
    height: var(--main-space);
    margin-left: calc(var(--main-space) / 5);
    padding: 10px;
    @include backgroundTransparency(#000, 1);
    display: flex;
    align-items: center;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }

  .icon-container svg{
    width: 100%;
    fill: #fff;
  }
  .icon-container svg path {
    fill: #fff;
  }

  .icon-container-youtube:hover svg path {
    fill: red !important;
  }

  .icon-container-linkedin:hover svg > * {
    fill: $linkedin !important;
  }

  .icon-container-instagram:hover svg > * {
    fill: $instagram !important;
  }

  .icon-container-whatsapp:hover svg > * {
    fill: $whatsapp !important;
  }

  .nomb a {
    margin-bottom: 0;
  }

  @include media-breakpoint-down(sm) {
    .icon-container {
      width: calc(var(--main-space) / 3 * 5);
      height: calc(var(--main-space) / 3 * 5);
    }
  }
</style>
