<template>
  <div class="service-box" :class="[classes, {'service-box-img': img}, `grid-item-${clasItem + 1}`]">
    <div class="service-box-inside">
      <template v-if="clasItem == 2">
          <div class="dashed dashed-left"></div>
          <div class="dashed dashed-right"></div>
      </template>
      <!-- <template v-if="clasItem == 3">
      </template> -->
      <div class="service-box-info-container" :class="{'not-hover': notHover}">
        <figure v-if="img">
          <img :src="require('../' + img)" alt="" />
        </figure>
        <h4 class="text-white service-box-item-text" :class="{'bcLiguria': description}">{{name.toUpperCase()}}</h4>
        <div class="arrow-container" v-if="linkTo">
          <ArrowIcon></ArrowIcon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowIcon from '../assets/img/arrow-icon.svg'
export default {
  name: 'HomeService',
  props: ['name', 'description', 'model', 'linkTo', 'classes', 'img', 'notHover', 'clasItem'],
  components: {
    ArrowIcon
  },
  data () {
    return {
      showFullDescription: false
    }
  }
}
</script>

<style scoped lang="scss">
.service-box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: calc(calc(100vw - 80px - calc(14px * 5) - 14px) / 6);
  // min-width: 228px;
  aspect-ratio: 1;
  z-index: 10;
  .dashed{
      width: 140px;
      height: 2px;
      background-color: #000;
      position: absolute;
      z-index: 100;
      opacity: 0;
  }
  .dashed-left{
      left: -70px;
      bottom: -71px;
      rotate: -45deg;
      transform-origin: bottom left;
  }
  .dashed-right{
      right: -70px;
      bottom: -71px;
      rotate: 45deg;
      transform-origin: bottom right;
  }
  .service-box-inside {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include backgroundTransparency(#000, 1);
  }
  h4{
    font-family: 'BC-Liguria', sans-serif;
    color: #8C9091 !important;
  }
  .service-box-info-container {
    // margin: var(--main-space);
    display: flex;
    justify-content: center;
    align-items: center;
    figure {
      margin: 0;
      margin-top: calc(-1 * var(--main-space));
      height: 200px;
      margin-bottom: var(--main-space);
      img {
        width: 100%;
        @supports (object-fit: cover) {
          object-fit: cover;
          object-position: center;
          height: 100%;
        }
      }
    }
    h4 {
      font-size: 100%;
    }
  }

  &.height-auto {
    .service-box-info-container {
      p {
        -webkit-line-clamp: inherit;
      }
    }
  }

  &.benefit-box-stacbond {
    h4 {
      text-align: center;
      font-size: 18px;
    }
  }
}
.grid-item-2{
      grid-column-start: 2;
      grid-row-start: 2;
      .service-box-inside{
        border-radius: 200px;
      }
  }
  .grid-item-3{
      grid-column-start: 3;
  }
  .grid-item-4{
      grid-column-start: 4;
      grid-row-start: 2;
      .service-box-inside{
        border-radius: 200px;
      }
  }
  .grid-item-5{
      grid-column-start: 7;
      grid-row-start: 1;
  }
  .grid-item-6{
      grid-column-start: 6;
      grid-row-start: 1;
  }

@media (max-width: 1024px){
  .service-box{
     min-width: 120px;
     .service-box-info-container{
        h4
        {
          font-size: 12px;
        }
      }
  }
}
</style>
