<template>
  <div class="menu-container">
    <div class="menu-content-container">
      <ul class="menu-items-container">
        <li><router-link :to="{name: 'About', params: {lang: this.$i18n.locale}}" @click.native="goTo('About')"><AboutUs /></router-link></li>
        <li><router-link :to="{name: 'Proyectos', params: {lang: this.$i18n.locale}}" @click.native="goTo('Works')"><Projects /></router-link></li>
        <li><router-link :to="{name: 'products', params: {lang: this.$i18n.locale}}" @click.native="goTo('Productos')"><Products /></router-link></li>
        <li><router-link :to="{name: 'Materiales', params: {lang: this.$i18n.locale}}" @click.native="goTo('Material')"><Materials /></router-link></li>
        <li><router-link :to="{name: 'DesignInsight', params: {lang: this.$i18n.locale}}" @click.native="goTo('DesignInsight')"><Lab /></router-link></li>
        <li><router-link :to="{name: 'Videos', params: {lang: this.$i18n.locale}}" @click.native="goTo('Videos')"><Videos /></router-link></li>
        <li><router-link :to="{name: 'Contact', params: {lang: this.$i18n.locale}}" @click.native="goTo('Contact')"><Contact /></router-link></li>
        <li><router-link :to="{name: 'Partners', params: {lang: this.$i18n.locale}}" @click.native="goTo('Partners')"><Partners /></router-link></li>
      </ul>
    </div>
    <HeaderContacts class="contact-icons-container" />
  </div>
</template>

<script>
import { EventBus } from '@/event-bus'
import AboutUs from './menuSVG/AboutUs.vue'
import Projects from './menuSVG/Projects.vue'
import Products from './menuSVG/Products.vue'
import Materials from './menuSVG/Materials.vue'
import Lab from './menuSVG/Lab.vue'
import Videos from './menuSVG/Videos.vue'
import Contact from './menuSVG/Contact.vue'
import Partners from './menuSVG/Partners.vue'
import HeaderContacts from './header/Contacts'
export default {
  components: {
    AboutUs,
    Projects,
    Products,
    Materials,
    Lab,
    Videos,
    Contact,
    Partners,
    HeaderContacts
  },
  methods: {
    goTo (name) {
      EventBus.$emit('toggleMenu', false)
      // this.$router.push({ name: name })
    }
  },
  mounted () {
    EventBus.$emit('bodyScroll', false)
  }
}
</script>

<style lang="scss" scoped>
.menu-container {
  width: 100vw;
  height: 100vh;
  position: relative;
  position: fixed;
  z-index: 100;
  @include backgroundTransparency(#000000, 0.5);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;

  .menu-content-container {
    height: 100%;
    width: 50%;
    @include backgroundTransparency(#000000, 1);

    .menu-items-container {
      margin-top: 150px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: end;
      padding-right: var(--main-space);

      li {
        // width: 300px;
        margin-bottom: calc(var(--main-space)/2);
        display: flex;
        justify-content: end;
        height: 30px;

        &:hover:deep( svg *) {
          fill: #FFF;
        }
        svg{
          height: 100%;
        }
      }
    }
  }

  .contact-icons-container {
    position: absolute;
    bottom: var(--main-space);
    right: 0;
    height: var(--main-space);
  }
}

@media screen and (min-width: 1600px){
  .menu-container {
    .menu-content-container {
      .menu-items-container {
        li {
          margin-bottom: calc(var(--main-space) / 2 * 1.5);
        }
      }
    }
  }
}
</style>
