var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer d-flex flex-column",class:{'menu-open': !_vm.showMenuIcon},style:({'z-index': _vm.numberPosition})},[_c('div',{staticClass:"logo-footer"},[_c('SVGLogo')],1),_c('ul',{staticClass:"footer-contact mb-0"},[_c('li',[_vm._v("→ "),_c('router-link',{attrs:{"to":{name: 'Contact', params: {lang: this.$i18n.locale}}}},[_vm._v("Contacto")])],1),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)]),_c('div',{staticClass:"footer-bottom mt-auto d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-between"},[_c('h4',{staticClass:"footer-phrase bcLiguria mb-0"},[_vm._v("Shaping the future of Architecture")]),_c('span',{staticClass:"copyrights mb-0 mt-4 mt-sm-0"},[_vm._v("2024 Grupo MSH - "+_vm._s(_vm.$t('footer.powerBy'))+" "),_c('a',{attrs:{"href":"https://daptee.com.ar/","target":"_blank"}},[_vm._v("Daptee | Agencia digital")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_vm._v("→ "),_c('a',{attrs:{"href":"http://wa.me/5491139512718","target":"_blank"}},[_vm._v("Whatsapp")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_vm._v("→ "),_c('a',{attrs:{"href":"https://maps.app.goo.gl/dTkUMEnECiycL2TW6","target":"_blank"}},[_vm._v("Google map (Planta industrial)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"social"},[_vm._v("→ "),_c('a',{attrs:{"href":"https://www.instagram.com/grupomshsa/","target":"_blank"}},[_vm._v("Instagram")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_vm._v("→ "),_c('a',{attrs:{"href":"https://www.linkedin.com/company/grupo-msh-s-a-/","target":"_blank"}},[_vm._v("Linkedin")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_vm._v("→ "),_c('a',{attrs:{"href":"https://www.youtube.com/@grupomsharquitecturametali7182","target":"_blank"}},[_vm._v("Youtube")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_vm._v("→ "),_c('a',{attrs:{"href":"https://www.facebook.com/grupomshsa","target":"_blank"}},[_vm._v("Facebook")])])
}]

export { render, staticRenderFns }