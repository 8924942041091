<template>
  <footer class="footer d-flex flex-column" :class="{'menu-open': !showMenuIcon}" :style="{'z-index': numberPosition}">
      <!-- <div class="footer-brand-power d-flex flex-md-row flex-column justify-content-between align-items-center">
        <span class="email-footer">hola@grupomsh.com</span>
      </div> -->
      <div class="logo-footer">
        <SVGLogo />
      </div>
      <ul class="footer-contact mb-0">
        <li>→ <router-link :to="{name: 'Contact', params: {lang: this.$i18n.locale}}">Contacto</router-link></li>
        <li>→ <a href="http://wa.me/5491139512718" target="_blank">Whatsapp</a></li>
        <li>→ <a href="https://maps.app.goo.gl/dTkUMEnECiycL2TW6" target="_blank">Google map (Planta industrial)</a></li>
        <li class="social">→ <a href="https://www.instagram.com/grupomshsa/" target="_blank">Instagram</a></li>
        <li>→ <a href="https://www.linkedin.com/company/grupo-msh-s-a-/" target="_blank">Linkedin</a></li>
        <li>→ <a href="https://www.youtube.com/@grupomsharquitecturametali7182" target="_blank">Youtube</a></li>
        <li>→ <a href="https://www.facebook.com/grupomshsa" target="_blank">Facebook</a></li>
      </ul>
      <div class="footer-bottom mt-auto d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-between">
        <h4 class="footer-phrase bcLiguria mb-0">Shaping the future of Architecture</h4>
        <span class="copyrights mb-0 mt-4 mt-sm-0">2024 Grupo MSH - {{$t('footer.powerBy')}} <a href="https://daptee.com.ar/" target="_blank">Daptee | Agencia digital</a></span>
      </div>
  </footer>
</template>

<script>
import SVGLogo from '@/assets/img/logo-footer.svg'
import Helper from '@/helpers/Helper'
import { isMobile } from 'mobile-device-detect'
import { EventBus } from '@/event-bus'
export default {
  components: {
    SVGLogo
  },
  name: 'Footer',
  data () {
    return {
      showMenu: true,
      scrollPos: 0,
      familyProducts: [],
      showLanguageIcon: true,
      showMenuIcon: true,
      showMoreIcon: false,
      showFamilyGridIcon: true,
      showFamilyIcons: true,
      showFundermaxIcon: false,
      showContactsIcons: false,
      familySelected: null,
      blackIcons: false,
      whiteIcons: false,
      iconsCompressed: false,
      showTitlePage: false,
      title: '',
      currentTitlePage: '',
      numberPosition: 99
    }
  },
  methods: {
    setTitlePage (title) {
      this.title = title
    },
    toggleContactsIcons (show) {
      this.showFamilyIcons = !show
      this.showContactsIcons = show
      this.showFundermaxIcon = !show
    },
    toggleGridFamilyDots () {
      this.showFamilyGridIcon = !this.showFamilyGridIcon
      this.toggleContactsIcons(!this.showFamilyGridIcon)
      this.numberPosition = 0

      if (!this.showFamilyGridIcon) {
        this.setTitlePage(this.$t('header.products'))
        this.toggleShowTitle(true)
      } else {
        if (this.currentTitlePage !== '') {
          this.setTitlePage(this.currentTitlePage)
          this.toggleShowTitle(true)
        } else {
          this.toggleShowTitle(false)
        }
      }

      EventBus.$emit('bodyScroll', this.showFamilyGridIcon)
    },
    listenOnScroll () {
      let pos = this.scrollPos
      window.addEventListener('scroll', (e) => {
        pos = window.scrollY
        if (!Helper.isHome() && !Helper.isFundermax()) {
          // this.showMenu = (pos < this.scrollPos)
          this.showMenu = pos === 0
          this.scrollPos = pos
        } else {
          let el = document.getElementById('home-section-services')
          if (Helper.isFundermax()) {
            el = document.getElementById('fundermax-section-services')
          }
          if (el !== null) {
            // this.showMenu = (pos < this.scrollPos || pos < el.offsetHeight)
            this.showMenu = pos < (el.offsetHeight - 200) // DEJO 200PX PARA QUE NO SE ENCIME CON EL TITULO
            this.scrollPos = pos
          }
        }
      })
    },
    toggleMenu () {
      this.showMenuIcon = !this.showMenuIcon
      this.showMenu = !this.showMenuIcon || (window.scrollY === 0)
      this.toggleContactsIcons(!this.showMenuIcon)
      this.numberPosition = 0
      EventBus.$emit('bodyScroll', this.showMenuIcon)
      if (this.showMenuIcon && this.$route.name === 'GPTW') {
        this.showFamilyIcons = false
      }
      // if (this.$route.path.includes('works')) {
      //   this.whiteHeaderProp = !this.whiteHeaderProp
      // }

      if (!this.showMenuIcon) {
        this.toggleShowTitle(false)
      } else {
        if (this.currentTitlePage !== '') {
          this.setTitlePage(this.currentTitlePage)
          this.toggleShowTitle(true)
        } else {
          this.toggleShowTitle(false)
        }
      }
    },
    toggleHoverMenu (toggle) {
      if (!isMobile) {
        this.showMoreIcon = toggle
      }
    }
  },
  mounted () {
    EventBus.$on('getFamilies', () => {
      return this.familyProducts
    })

    EventBus.$on('toggleMenuIcon', (toggle) => {
      this.showMenuIcon = toggle
    })
    EventBus.$on('toggleGridFamilyIcon', (toggle) => {
      this.showFamilyGridIcon = toggle
    })
    EventBus.$on('headerFamilySelected', (family) => {
      this.familySelected = family
      this.iconsCompressed = (family !== null)
    })
    EventBus.$on('toggleHeaderFamilyIcons', (toggle) => {
      this.showFamilyIcons = toggle
    })
    EventBus.$on('toggleHeaderBlack', (toggle) => {
      this.blackHeader = toggle
    })
    EventBus.$on('showTitlePage', (titlePage) => {
      this.currentTitlePage = titlePage
      this.setTitlePage(titlePage)
      this.toggleShowTitle(true)
    })
    EventBus.$on('showLogo', () => {
      this.currentTitlePage = ''
      this.setTitlePage('')
      this.toggleShowTitle(false)
    })
    EventBus.$on('showContactsIcons', (show) => {
      this.toggleContactsIcons(show)
    })
  },
  watch: {
    showMenuIcon: {
      handler () {
        EventBus.$emit('toggleMenu', !this.showMenuIcon)
        if (!this.showMenuIcon || this.$route.name === 'GPTW') {
          EventBus.$emit('toggleGridFamilyDots', false)
        }
      }
    },
    showFamilyGridIcon: {
      handler () {
        EventBus.$emit('toggleGridFamilyDots', !this.showFamilyGridIcon)
        if (!this.showFamilyGridIcon) {
          EventBus.$emit('toggleMenu', false)
          this.hideContactsIcons()
        }
      }
    }
  }
}
</script>

<style lang="scss">
.footer {
  background-color: #1A1A1A;
  padding: var(--main-space);
  width: 100%;
  position: sticky;
  bottom: 0;
  height: 100vh;
  .logo-footer{
    margin-bottom: calc((var(--main-space) * 1.6));
      svg{
        position: relative;
        width: 680px;
        max-width: 100%;
      }
    }
    .footer-contact{
      li{
        font-size: 22px;
        line-height: 1.45;
        color: $footer-text;
        transition: all 0.3s ease-in-out;
        &.social{
          padding-top: 20px;
        }
        &:hover{
          padding-left: 5px;
        }
        a{
          font-size: 22px;
          color: $footer-text;
        }
      }
    }
    .footer-phrase{
      font-size: 32px;
      color: $footer-text;
    }
    .copyrights, .copyrights a{
      font-size: 14px;
      color: $footer-text;
    }
    .copyrights a{
      color: #ffffff;
    }
    .footer-brand {
      font-size: 1.2rem;
      color: $footer-text;
      display: block;
      margin-bottom: 18rem;
    }
  .footer-brand-power {
    row-gap: 2rem;
    margin-bottom: 14rem;
    .email-footer{
      font-size: 2rem;
      font-family: 'BC-Liguria', sans-serif;
      color: #8C9091;
      opacity: 0;
    }
    .footer-power-by {
      font-size: 0.8rem;
      color: $footer-text;
      a {
        font-size: 0.8rem;
        color: #8C9091;
      }
    }
  }
}

.header-icon {
  fill: #8C9091;
  path {
    fill: #8C9091;
  }
}

@include media-breakpoint-down(sm) {
  .footer {
    .footer-contact{
      li{
        font-size: 20px;
        line-height: 2;
        a{
          font-size: 20px;
        }
      }
    }
    .footer-phrase{
      font-size: 24px;
    }
    .copyrights, .copyrights a{
      font-size: 12px;
    }
    /*height: calc(var(--main-space) * 4);*/
    .footer-brand {
      width: 100%;
      .email-footer{
        font-size: 4rem;
      }
    }
    .footer-power-by {
      width: 100%;
      text-align: right;
    }
  }
}
@include media-breakpoint-down(xs) {
  .footer {
    .footer-power-by {
      margin-top: calc(var(--main-space)/2);
      text-align: left;
    }
  }
}
@media (max-width: 1200px){
  .footer{
    padding-block-end: 40px;
  }
}
</style>
