<template>
  <div class="masonry-container" :style="{'--1': lengthInputs}">
    <template v-if="obras">
        <article class="masonry-item" v-for="(image, index) in obras" :key="image.id" :style="{'--i': index}" >
            <ImageComponent :data="image" />
        </article>
    </template>
    <template v-if="materials">
        <article class="masonry-item" v-for="(material, index) in materials" :key="material.id" @click.stop.prevent="selectMaterial(material)" :style="{'--i': index}" >
            <ImageComponent :data="material" />
        </article>
    </template>
    <template v-if="videos">
      <article class="masonry-item" v-for="(video, index) in videos" :key="video.id" @click.stop.prevent="showThisVideo(video)" :style="{'--i': index}" >
            <ImageComponent :data="video" />
      </article>
    </template>
    <template v-if="products">
      <article class="masonry-item" v-for="(product, index) in products.productos" :key="product.id" :style="{'--i': index}" >
            <ImageComponent :data="product" :color="products.color" />
      </article>
    </template>
    <template v-if="fotos">
      <article class="masonry-item" v-for="(foto, index) in fotos" :key="foto" :style="{'--i': index}" @click.stop.prevent="selectProduct(foto)" >
            <ImageComponent :data="foto" />
      </article>
    </template>
  </div>
</template>

<script>
import ImageComponent from './Image.vue'
export default {
  name: 'DinamicGridImages',
  props: ['obras', 'materials', 'videos', 'products', 'fotos'],
  emits: ['showWork', 'selectMaterial', 'showThisVideoEmit', 'selectProduct'],
  components: {
    ImageComponent
  },
  computed: {
    lengthInputs () {
      if (this.obras) {
        return this.obras.length
      }
      if (this.materials) {
        return this.materials.length
      }
      if (this.videos) {
        return this.videos.length
      }
      return 0
    },
    hayMateriales () {
      return this.materiales.length > 0
    }
  },
  methods: {
    selectMaterial (material) {
      this.$emit('selectMaterial', material)
    },
    showThisVideo (video) {
      this.$emit('showThisVideoEmit', video)
    },
    selectProduct (product) {
      this.$emit('selectProduct', product)
    }
  }
}
</script>

<style lang="scss">
  .masonry-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-content: center;
    // width: calc(100% - calc(2*var(--main-space)));
    padding: 0 40px 0 40px;
    gap: calc(var(--main-space) / 2);
    // background-color: #000;
    // padding-inline: var(--main-space);
    // padding-block: calc(var(--main-space) * 2);
    background: #1A1A1A;
    .masonry-item {
      width: 100%;
      height: 500px;
      position: relative;
      cursor: pointer;
    }
}

  @include media-breakpoint-down(lg) {
    .masonry-container {
      // padding-block: calc(var(--main-space) / 4);
      .masonry-item {
        height: inherit;
        aspect-ratio: 3/4;
        .masonry-item-overlay {
          display: block;
          opacity: 0;
          .obra-overlay-info-text {
            font-size: 12px;
            display: block;
            width: 100%;
          }
          .obra-overlay-info-line-2 {
            margin-bottom: 0;
          }
        }
      }
    }
  }
</style>
