<template>
    <article class="products-article">
      <img @click="toPageFamily" class="products-article-img" :src="products.icon" :alt="products.name" />
      <div class="products-article-carrousel">
        <figure class="products-article-carrousel-slide products-article-carrousel-first-container">
          <img @click="toPageFamily" :src="products.foto" :alt="products.name" />
          <figcaption class="products-article-carrousel-slide-figcaption">
            <div>
              <h3 class="bcLiguria" @click="toPageFamily" :style="{color: products.color}">{{ products.name }}</h3>
              <p v-if="products.text1">{{ products.text1 }}</p>
            </div>
            <span class="bcLiguria" :style="{color: products.color}">Descargar .PDF</span>
          </figcaption>
        </figure>
      </div>
      <!-- <Carousel @pageChange="pageChange" @mouseenter="pageChange" class="products-article-carrousel" tag="div" :perPage="page" :paginationEnabled="false">
        <Slide class="products-article-carrousel-slide products-article-carrousel-first-container" tag="figure">
          <img @click="toPageFamily" :src="products.foto" :alt="products.name" />
          <figcaption class="products-article-carrousel-slide-figcaption">
            <div>
              <h3 @click="toPageFamily" :style="{color: products.color}">{{ products.name }}</h3>
              <p v-if="products.text1">{{ products.text1 }}</p>
            </div>
            <span :style="{color: products.color}">.PDF</span>
          </figcaption>
        </Slide>
        <Slide class="products-article-carrousel-slide products-article-carrousel-family" tag="figure" v-for="product in products.productos" :key="product.id">
          <img v-if="product?.fotos[0]?.foto" :src="product?.fotos[0]?.foto" :alt="product.nombre" />
          <img v-else :src="product?.fotos[1]?.foto" :alt="product.nombre" />
        </Slide>
      </Carousel> -->
    </article>
</template>

<script>
export default {
  name: 'ProductLayout',
  props: ['products'],
  data () {
    return {
      page: 1.03
    }
  },
  computed: {
  },
  methods: {
    pageChange (e) {
      // console.log(e)
      if (e === 0) {
        this.page = 1.03
      } else {
        this.page = 2
      }
    },
    toPageFamily () {
      // this.$router.push({ path: '/familias/' + this.products.id })
      this.$router.push({ name: 'family-product', params: { familyProduct: this.products.id + '-' + this.products.path, object: this.products.productos } })
    }
  },
  mounted () {}
}
</script>

<style lang="scss" scoped>
  .products-article{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: start;
    column-gap: 1.5rem;
    &-img{
      max-width:2%;
      cursor: pointer;
    }
    &-carrousel{
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1.5rem;
      &-slide{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: start;
        cursor: pointer;
        gap: 1.5rem;
        height: 700px;
        &:active{
          cursor: move;
        }
        img{
          // width: 1000px;
          // width: calc(100% - 40px);
          height: 700px;
          object-fit: cover;
          // border: 1px solid red;
          // max-width: 80%;
        }
        &-figcaption{
          min-height: 100%;
          width: 480px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          // border: 1px solid red;
          h3{
            margin-block-end: 1.5rem;
          }
          p{
            width: 90%;
            margin: 0;
            color: #8C9091;
            font-size: 1.2rem;
          }
          span{
            margin-right: auto;
            background: black;
            padding: 0.5rem 1rem;
          }
        }
      }
    }
  }

  .products-article-carrousel-first-container {
    img {
      width: 60%;
    }
    figcaption {
      width: 40%;
    }
  }
.products-article-carrousel-family img {
  width: calc(100% - 40px);
  max-width: 800px;
}

  @media (max-width:1280px){
    .products-article{
      &-img{
        max-width:4%;
        cursor: pointer;
      }
      &-carrousel{
        img{
          width: 700px;
          object-fit: cover;
          max-width: 50%;
          height: 500px;
        }
      }
    }
    p{
      width: 80% !important;
      margin-block-end: 1.5rem;
      font-size: 1rem !important;
    }
  }
  @media (max-width:1024px){
    .products-article{
      &-carrousel{
        height: 300px;
        &-slide{
          height: 300px;
        }
        img{
          width: 100%;
          object-fit: cover;
          max-width: 90%;
          height: 300px;
        }
      }
    }
    h3{
      font-size: 1.5rem;
    }
    p{
      line-height: 120% !important;
    }
  }
  @media (max-width:920px){
    h3{
      font-size: 1rem;
    }
    p{
      font-size: 0.8rem !important;
    }
  }
</style>
